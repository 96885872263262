const loanRequirements = [
    {
        "title": "Valid National ID",
        "description": "Make sure you have a valid Malawian national ID."
    },
    {
        "title": "NBS Bank Salary Account",
        "description": "Make sure to have an active salary account that is atleast six(6) months old."
    },
    {
        "title": "Clean CRB Report",
        "description": "Make sure you have never defaulted a loan with NBS or any institution in Malawi"
    }
]

export default loanRequirements;